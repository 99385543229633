import React from "react"
import { box, pack, ship } from "../../utils/imageUpload"

export function ShopHeader() {
  return (
    <>
      <div className="">
        <h1 className="BundaySlabBold">Party perfect at a moment's notice!</h1>
        <p>
          Stock up on your favorites for an upcoming party or to stash in the
          freezer to stay ready for a last minute gathering.
        </p>
      </div>
      <div className="story-line">
        <div className="item">
          <h6>1.</h6>
          <img src={box} alt="" />
          <p>Select your bundle depending on what suits you best.</p>
        </div>
        <div className="item">
          <h6>2.</h6>
          <img src={pack} alt="" />
          <p>Add products to your assortment and checkout.</p>
        </div>
        <div className="item">
          <h6>3.</h6>
          <img src={ship} alt="" />
          <p>Your Savorly delivery is on its way - Bon Appétit!</p>
        </div>
      </div>
    </>
  )
}
