import React, { useState, useEffect } from "react"
import Image from "gatsby-image"
import { Product } from "../../styles/NewStoreStyle"

const ShopProduct = ({
  index,
  product,
  chooseBundle,
  setChangeProductImg,
  changeProductImg,
  setBigImgDisplay,
  addToBundler,
  removeFromBundler,
  setpopUp
}) => {
  const [counter, setCounter] = useState(0)
  const [border, setBorder] = useState(false)

  useEffect(() => {
    setCounter(0)
  }, [chooseBundle])

  const add = () => {
    setCounter(counter + 1)
  }

  const remove = () => {
    if (counter > 0) setCounter(counter - 1)
  }

  const resetImages = (index) => {
    if(chooseBundle ){

      setChangeProductImg(index)
      setBigImgDisplay(0)
      setpopUp(true)
    }
  }

  useEffect(() => {
    if (changeProductImg === index) {
      setBorder(true)
    } else {
      setBorder(false)
    }
  }, [changeProductImg])

  return (
    <Product border={border} selected={counter>0 || chooseBundle===6} bundleSix= {chooseBundle ===6}>
      <div onClick={() => resetImages(index)} className="product-img">
        <Image
          fadeIn={true}
          fluid={product.images[0].localFile.childImageSharp.fluid}
        />
      </div>
      <div>
        <p>{product.title}</p>
      </div>
      {!chooseBundle || chooseBundle === 6 ? (
        <div className="button">
          <button disabled>-</button>
          {chooseBundle ? "1" : "0"}
          <button disabled>+</button>
        </div>
      ) : (
        <React.Fragment>
        {product.title === "Mezze Bites" ? 
        (
          <div className="warning">
            Out of stock
          </div>)
        :
        (
          <div className="button">
            <button 
              className="btn-hover"
              onClick={() => removeFromBundler({ remove, index, counter })}
            >
              -
            </button>
            {counter}
            <button
              className=" btn-hover"
              onClick={() => addToBundler({ add, counter, product })}
            >
              +
            </button>
          </div>
        )}
        </React.Fragment>
      )}
    </Product>
  )
}
export default ShopProduct
