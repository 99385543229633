import React from "react"
import { useCarouselContext } from "../../Context/CarouselContext"
export function ArrowSection() {
  const { prev, next } = useCarouselContext()
  return (
    <div className="arrows">
      <span onClick={prev}>{"<"}</span>
      <span onClick={next}>{">"}</span>
    </div>
  )
}
