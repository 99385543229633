import React from "react"
import DiscoverItem from "../components/template/DiscoverItem"
import { CarouselWrapper } from "../Context/CarouselContext"
import { PopUpSection } from "../styles/ShopStyles"

const PopupProduct = ({ popUpProduct, closePopUp }) => {
  return (
    <CarouselWrapper product={popUpProduct}>
      <PopUpSection>
        <div className="inner">
          <DiscoverItem product={popUpProduct} closePopUp={closePopUp} />
        </div>
        <div onClick={closePopUp} className="overlay"></div>
      </PopUpSection>
    </CarouselWrapper>
  )
}

export default PopupProduct

