import React from "react"
import { PopUpContainer } from "../../styles/ShopStyles"
import { ArrowSection } from "../ShopComponents/ArrowSection"
import { ClosePopUpButton } from "../ShopComponents/ClosePopupButton"
import { CustomCarousel } from "../ShopComponents/CustomCarousel"

const DiscoverItem = ({ closePopUp }) => {
  return (
    <PopUpContainer>
      <ArrowSection />
      <div className="popUpProductArea">
        <CustomCarousel />
      </div>
      <ClosePopUpButton closePopUp={closePopUp} />
    </PopUpContainer>
  )
}
export default DiscoverItem

