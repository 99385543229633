import styled from "styled-components"

export const ShopContainer = styled.div`
  padding-top: 100px;
  .phone {
    display: none;

    h1 {
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
  }
  .laptop {
    display: block;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
  }

  .story-line{
    margin-top:46px;
    display:grid;
    /* grid-template-columns:1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    
    grid-gap:.7rem;

    .item{
      h6{
        border:.5px solid var(--darkBlue);
        border-radius:50%;
        padding: 4px;
      }
      p{
      max-width:144px;
      }
      img{
        max-width:100px;
      }
      display:flex;
      justify-content:space-evenly;
      grid-gap:10px;
      align-items:center;
    }

  }
  @media (max-width: 1256px) {
    padding-top: 30px;
    /* grid-template-columns: auto;
    grid-template-rows: 1fr 1fr; */
    /* grid-gap: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    .phone {
      display: block;

      /* width: 90%; */
      max-width: 500px;
    }
    .laptop {
      display: none;
    }
    .img-container {
      /* height: 500px; */
      min-height: 500px;
      /* display: flex;
      justify-content: space-between;
      width: 90%; */
      width: 100%;
    }
  }

  background-color: var(--white);
  color: var(--darkBlue);

  min-height: 100vh;
  margin: 0 auto;

  p,
  h1,
  h3,
  img {
    margin: 0;
  }

  //////

  .main-img-container {
    width: 80%;
    height: 50%;

    @media (max-width: 1256px) {
      height: 500px;
    }
  }
  .img-container {
    @media (max-width: 1256px) {
      display: none;
    }
    .main-img-container {
      @media (max-width: 1256px) {
        margin: 0 auto;
        width: 90%;
      }
    }
    .gatsby-image-wrapper {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      justify-content: flex-start;
      img {
        width: auto !important;
        @media (max-width: 1256px) {
          width: 100% !important;
        }
        object-fit: contain !important;
      }
    }
    .thumbnail-container {
      overflow-x: scroll;
      display: flex;
      align-items: center;
      margin-top: 10px;

      div {
        margin-right: 10px;
      }

      @media (max-width: 1256px) {
        justify-content: center;
        /* display: none; */
        div {
          /* margin-bottom: 10px;
          margin-right: 0; */
        }
        /* flex-direction: column; */
      }
    }
  }

  //////
  .shop-container {
    @media (min-width: 760px) {
      width: 100%;
    }
    @media (max-width: 691px) {
      width: 90%;
      max-width: 500px;
    }

    .btn-select-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      .select {
        display: flex;
        justify-content: space-between;
        background: transparent;
        color: var(--darkBlue);
        border: 1px solid var(--darkBlue);
        align-items:center;
        /* width: 100%; */
      /* width: 230px; */
        padding: 3px 10px;
        min-height:50px;
        border-radius: 10px;
        font-size:22px;

        span {
          color: var(--darkBlue);
          text-decoration: line-through;

          :nth-child(2) {
            /* color: var(--darkBlue); */
            font-size: 1.3rem;
            font-weight: bolder;
            text-decoration: none;
          }
        }
        input {
          margin: 0 4px;
        }

        :hover {
          cursor: pointer;
          background: var(--darkBlue);
          color: white;
          span {
            color: white;
          }
        }

        &.out-of-stock {
          background-color: #cccccc;
          .warning {
            color:var(--orange);
            text-decoration: none;
          }
          :hover{
            cursor: default;
            background-color: #cccccc;
            color: var(--darkBlue);
            span {
              color: var(--darkBlue);
          }
          .warning {
            color:var(--orange);
            text-decoration: none;
          }
          }
        }
      }
      .selected {
        color: white;
        span {
          color: var(--white);
        } 
      /*   span {
          :nth-child(2) {
            color: var(--darkBlue);
          }
        } */
        cursor: pointer;
        border: 1px solid var(--transparent);

        background: var(--darkBlue);
        :active {
          transform: translateY(0px);
        }
      }
      .warning {
        color:red;
      }
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        min-height: 500px;
      }
      grid-gap: 48px;
      min-height: 400px;
    }

    .disabled {
      opacity: 0.5;
    }

    .explore {
      margin-bottom: 0 !important;
    }
    .step {
      margin: 30px 0;
      h3 {
        margin-bottom: 17px;
        /* text-transform: uppercase; */
        color: var(--darkBlue);
      }
    }

    .layout-product-and-progress {
      display: grid;      

      grid-template-columns: 1fr auto;

      @media(max-width:768px){
      grid-template-columns: 1fr ;

      }
    }
    .checkout-container {
      /* border: 1px solid var(--darkBlue); */
      padding: 10px 10px 25px;
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      text-align: center;
      max-width:489px;
      margin:0 auto;

      h3 {
        margin-top: 10px;
        font-weight: 400;
        font-size: 1.3rem;
      }
      .checkout {
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 679px) {
          flex-direction: column;

        }

        div {
          color: var(--white);
          background: var(--darkBlue);
          min-height: 35px;
        }

        button {
          width: 100%;
          padding: 15px 30px;
          background-color: var(--orange);
          font-size: 1.5rem;

          border: none;

          border-radius: 10px;

          color: var(--darkBlue);
          text-align: center;
          transition: all 0.2s;
          text-transform: uppercase;
          :hover {
            cursor: pointer;
          }

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }



`

export const Product = styled.div`
  /* height: 100px; */
  /* width: 100px; */

  .warning {
    color:var(--orange);
    text-align: center;
  }
  .product-img {
    :hover {
      @media (min-width: 1256px) {
        cursor: pointer;
      }
    }
    .gatsby-image-wrapper {
      @media (min-width: 768px) {
      width: 180px !important;
      height: 180px !important;

      }

      width: 100px !important;
      height: 100px !important;
      display: flex;
      justify-content: center;
      img {
        object-fit: contain !important;
        @media (min-width: 1256px) {
          border: ${(props) =>
            props.border ? "3px solid var(--darkBlue)" : ""};
        }
        border-radius:9px;
        width: auto !important;
      }
    }
    display: flex;
    justify-content: center;
    width: 100% !important;
    /* height: 100%; */
  }

  p {
    margin: 0;
    text-align: center;
    color: var(--darkBlue);
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 15px;
    }

 
  }
  .button {
    border: 1px solid var(--darkBlue);
    color: ${(props) =>
            props.selected ? "white" : "var(--darkBlue)"};
    border-radius: 12px;
    font-size:22px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (min-width: 768px) {
      width: 50%;
    margin: 0 auto;
    }
   
    background: ${(props) =>
            props.selected ? "var(--darkBlue)" : "transparent"};
        
       
    button {
      display: ${(props) => 
            props.bundleSix ? "none" : ""};
      color: ${(props) =>
            props.selected ? "white" : "var(--darkBlue)"};
        
      width: 100%;
      border: none;
      background-color: transparent;
    }
    

    .btn-hover {
      :hover {
        cursor: pointer;
      }
    }
  }
`
