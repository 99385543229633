import React, { useMemo, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BodyWrapper from "../templates/BodyWrapper"
import ShopProduct from "../components/template/ShopProduct"
import CookingBanner from "../components/template/CookingBanner"
import { ShopContainer } from "../styles/NewStoreStyle"
import { useStore } from "../Context/StoreContext"
import { useBundle } from "../hooks/useBundle"
import ReactPixel from "react-facebook-pixel"
import PopupProduct from "../templates/Popup.product"
import { progressBarItem } from "../utils/imageUpload"
import { ProgressBar, Progress } from "../styles/ShopStyles"
import { ShopHeader } from "../components/ShopComponents/ShopHeader"
import { BundleChoice } from "../components/ShopComponents/BundleChoice"

const Store = ({ data }) => {
  const { state } = useStore()
  const allProducts = data.products.edges.map(({ node }) => node)
  const [welcomeBundle] = data.welcomeBundle.edges.map(({ node }) => node)
  const [chooseBundle, setChooseBundle] = useState(5)
  const [changeProductImg, setChangeProductImg] = useState(null)
  const [bigImgDisplay, setBigImgDisplay] = useState(0)
  const [popUp, setPopUp] = useState(false)

  const {
    progressBar,
    addToBundler,
    removeFromBundler,
    addToCart,
    bundleChoices,
  } = useBundle({
    size: chooseBundle,
    allProducts,
    welcomeBundle,
  })

  const [BigCounter, setBigCounter] = useState(0)

  const changeBundle = (size) => {
    setChooseBundle(size)
  }
  async function addToCartBonanza() {
    if (state.isLoading) {
      return
    } else {
      await addToCart()
      setBigCounter(0)
      setChooseBundle(null)

      for (let product in bundleChoices) {
        ReactPixel.track("AddToCart", {
          content_name: bundleChoices[product].title,
        })
      }

      if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "add_to_cart", {
          items: [...bundleChoices],
        })
      }
    }
  }

  const chosenProduct = useMemo(() => {
    const chosenIndex = changeProductImg || 0
    const [{ products }] = allProducts
    return products[chosenIndex]
  }, [changeProductImg])


  return (
    <Layout>
      <SEO title="Shop" />
      {popUp && (
        <PopupProduct
          products={allProducts}
          productIndex={changeProductImg}
          popUpProduct={chosenProduct}
          closePopUp={() => setPopUp((p) => !p)}
        />
      )}
      <BodyWrapper bg={"white"}>
        <ShopContainer>
          <div className="shop-container">
            <ShopHeader />
            <BundleChoice
              changeBundle={changeBundle}
              chooseBundle={chooseBundle}
            />
            <div className={`step ${chooseBundle ? "" : "disabled"}`}>
              <h3 className="phone">3. Choose Assortment</h3>
              <h3 className="laptop">2. Choose Assortment</h3>
              <div className="layout-product-and-progress">
                <div className="grid">
                  {allProducts[0].products.map((product, i) => {
                    return (
                      <ShopProduct
                        key={i}
                        index={i}
                        chooseBundle={chooseBundle}
                        setChangeProductImg={setChangeProductImg}
                        changeProductImg={changeProductImg}
                        setBigImgDisplay={setBigImgDisplay}
                        product={product}
                        BigCounter={BigCounter}
                        setBigCounter={setBigCounter}
                        addToBundler={addToBundler}
                        removeFromBundler={removeFromBundler}
                        setpopUp={setPopUp}
                      />
                    )
                  })}
                </div>

                <ProgressBar size={progressBar.length}>
                  {progressBar.map((el, i) => (
                    <Progress key={i} state={el}>
                      <img src={progressBarItem} alt="" />
                    </Progress>
                  ))}
                </ProgressBar>
              </div>
            </div>
            <div
              className={`step ${
                bundleChoices.length == progressBar.length ? "" : "disabled"
              }`}
            >
              <div className="checkout-container">
                <div className="checkout">
                  <button
                    onClick={addToCartBonanza}
                    disabled={bundleChoices.length !== progressBar.length}
                  >
                    Add To Cart
                  </button>
                </div>
                <h3>Includes Free Shipping & Handling</h3>
              </div>
            </div>
          </div>
        </ShopContainer>
      </BodyWrapper>
      <CookingBanner />
    </Layout>
  )
}

export default Store

export const PRODUCTS_LISTING = graphql`
  query PRODUCTS_LISTING {
    bundleMain: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/BundleSavorly/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    products: allShopifyCollection(filter: { handle: { eq: "shop" } }) {
      edges {
        node {
          products {
            id
            handle
            description
            title
            shopifyId
            productType
            variants {
              title
              shopifyId
              price
              availableForSale
            }
            images {
              localFile {
                url
                childImageSharp {
                  fluid {
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    welcomeBundle: allShopifyProduct(
      filter: { handle: { regex: "/welcome/gi" } }
    ) {
      edges {
        node {
          id
          handle
          description
          title
          shopifyId
          productType
          variants {
            title
            shopifyId
            price
            availableForSale
          }
        }
      }
    }
  }
`
