import React from "react"
import { ClosePopUp } from "../../styles/ShopStyles"

export function ClosePopUpButton({ closePopUp }) {
  return (
    <ClosePopUp onClick={closePopUp}>
      <div className="one">
        <div className="two"></div>
      </div>
    </ClosePopUp>
  )
}
