import { useImmerReducer } from "use-immer"
import { useSwipeable } from "react-swipeable"
import { useEffect, useState } from "react"

const transitionTime = 600
const threshold = (target) => {
  const width = target.clientWidth
  return width / 4
}
const limit = 1.2
// animation to be used when bouncing back
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`
// animation to be used when automatically sliding
const smooth = `transform ${transitionTime}ms ease`

function swiped(e, dispatch, length, dir) {
  const t = threshold(e.event.target)
  const d = Math.abs(dir * e.deltaX)
  if (d >= t) {
    dispatch({
      type: dir > 0 ? "next":"prev",
      length,
    })
  } else {
    dispatch({ type: "drag", offset: 0 })
  }
}

const initialState = {
  active: 0,
  offset: 0,
  desired: 0,
}

function next(length, current) {
  return (current + 1) % length
}

function previous(length, current) {
  return (current - 1 + length) % length
}

function carouselReducer(draft, action) {
  switch (action.type) {
    case "jump": {
      draft.desired = action.desired
      return
    }
    case "next": {
      draft.desired = next(action.length, draft.active)
      return
    }
    case "prev": {
      draft.desired = previous(action.length, draft.active)
      return
    }
    case "done": {
      draft.offset = NaN
      draft.active = draft.desired
      return
    }
    case "drag": {
      draft.offset = -action.offset
      return
    }
    default: {
      return draft
    }
  }
}

export function useCarousel(length, interval, options = {}) {
  const { slidesPresented = 1 } = options
  const shadowSlides = 2 * slidesPresented

  const n = Math.max(1, Math.min(slidesPresented, length))

  const totalWidth = 100 / n

  const [container, setContainer] = useState(undefined)

  const [state, dispatch] = useImmerReducer(carouselReducer, initialState)

  const { ref, onMouseDown } = useSwipeable({
    onSwiping(e) {
      const sign = e.deltaX > 0 ? -1 : 1
      dispatch({
        type: "drag",
        offset:
          sign * Math.min(Math.abs(e.deltaX), limit * container.clientWidth),
      })
    },
    onSwipedLeft(e) {
      swiped(e, dispatch, length, 1)
    },
    onSwipedRight(e) {
      swiped(e, dispatch, length, -1)
    },
    trackMouse: true,
    trackTouch: true,
  })

  const handlers = {
    onMouseDown,
    ref(container) {
      setContainer(container && container.firstElementChild)
      return ref(container)
    },
  }

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "next", length }), interval)
    return () => clearTimeout(id)
  }, [state.offset, state.active])

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "done" }), transitionTime)
    return () => clearTimeout(id)
  }, [state.desired])

  const style = {
    transform: "translateX(0)",
    width: `${totalWidth * (length + shadowSlides)}%`,
    left: `-${(state.active + 1) * totalWidth}%`,
  }

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired)
    const pref = Math.sign(state.offset || 0)
    const dir =
      (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active)
    const shift = (totalWidth * (pref || dir)) / (length + shadowSlides)
    style.transition = smooth
    style.transform = `translateX(${shift}%)`
  } else if (!isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`
    } else {
      style.transition = elastic
    }
  }

  return [
    state.active,
    (n) => dispatch({ type: "jump", desired: n }),
    handlers,
    style,
    () => dispatch({ type: "next", length }),
    () => dispatch({ type: "prev", length }),
  ]

  return [state, dispatch]
}
