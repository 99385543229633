import React from "react"
import styled from "styled-components"
import { useCarouselContext } from "../../Context/CarouselContext"

export function Dots() {
  const { active, product, setActive } = useCarouselContext()
  return product.images.map((_, i) => (
    <DotStyles
      key={i}
      active={i === active}
      onClick={() => setActive(i)}
      className="dot"
    />
  ))
}

const DotStyles = styled.span`
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  margin: 0 1px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "var(--darkBlue)" : "var(--unSelected)"};

  &:hover {
    background: var(--darkBlue);
  }
`
