import React from "react"
import styled from "styled-components"
import { Dots } from "./DotSection"
import GatsbyImage from "gatsby-image"
import { useCarouselContext } from "../../Context/CarouselContext"

export function CustomCarousel() {
  return <MyContainer />
}

function WithImage({ index, url }) {
  const { slides } = useCarouselContext()
  const fluid =
    typeof index === "number"
      ? slides[index].localFile.childImageSharp.fluid
      : url
  return (
    <>
      {" "}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
        }}
      ></div>
      <GatsbyImage fluid={fluid} />
    </>
  )
}

const MyContainer = () => {
  const {
    slides,
    length,
    handlers,
    style,
    beforeIndices,
    afterIndices,
  } = useCarouselContext()

  return (
    length > 0 && (
      <CarouselContainer>
        <TCarousel>
          <div
            style={{
              ...style,
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              overflow: "hidden",
              position: "relative",
            }}
            {...handlers}
          >
            {beforeIndices.map((e) => {
              return (
                <CarouselChild key={e}>
                  <WithImage index={e} />
                </CarouselChild>
              )
            })}
            {slides.map((e, i) => {
              return (
                <CarouselChild key={i}>
                  <WithImage url={e.localFile.childImageSharp.fluid} />
                </CarouselChild>
              )
            })}
            {afterIndices.map((e) => {
              return (
                <CarouselChild key={e}>
                  <WithImage index={e} />
                </CarouselChild>
              )
            })}
          </div>
        </TCarousel>
        <div className="dots">
          <Dots />
        </div>
      </CarouselContainer>
    )
  )
}

function CarouselChild({ children }) {
  return <ImageSection>{children}</ImageSection>
}

const TCarousel = styled.div`
  position: relative;
  overflow: hidden;
  height: 90%;
  width: 90%;
`

const ImageSection = styled.section`
  height: 90%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 90%;
    height: 400px;
    img {
      object-fit: contain !important;
    }
    @media (max-width: 769px) {
      height: 320px;
    }

    @media (max-width: 500px) {
      height: 100%;
    }
  }
`

const CarouselContainer = styled.div`
  --unSelected: rgba(0, 48, 87, 0.3);
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .dots {
    display: flex;
    width: 40%;
    justify-content: space-evenly;
  }
`
