import { useState, useEffect } from "react"
import { useStore } from "../Context/StoreContext"

export function useBundle({
  size = 1,
  allProducts: [{ products: allProducts }],
  welcomeBundle,
}) {
  const { cleanerAddToCart } = useStore()

  const options = [...Array(size).keys()].map(() => false)

  const selected = size === 6 ? welcomeBundle : null
  const [warn, setWarning] = useState("")

  useEffect(() => {
    if (size === 6) {
      setBundleChoices(allProducts.map((el) => ({ ...el, quantity: 1 })))
      setProgressBar(options.map(() => true))
    } else {
      setProgressBar(options)
      setBundleChoices([])
    }
  }, [size])
  const [bundleChoices, setBundleChoices] = useState([])

  const [progressBar, setProgressBar] = useState(options)

  function updateProgressBar(arr) {
    const newProgressBar = [...progressBar].map((el, i) =>
      i >= arr.length ? false : true
    )
    setProgressBar(newProgressBar)
  }

  function updateWarning(msg, time = 1500) {
    setWarning(msg)
    return setTimeout(() => {
      setWarning("")
    }, time)
  }

  async function addToCart() {
    const reducerFromTheOtherSide = bundleChoices.reduce((acc, val) => {
      const currentProduct = acc.find((el) => el.handle === val.handle)
      if (currentProduct) {
        currentProduct.quantity++
        return acc
      }

      return [...acc, { ...val, quantity: 1 }]
    }, [])
    cleanerAddToCart(reducerFromTheOtherSide, bundleChoices.length, selected)
  }

  function addToBundler({ add, counter, product }) {
    // function addToBundler({ product, add, counter }) {
    // console.log("product:", product)
    if (counter >= size || bundleChoices.length >= size) {
      return updateWarning(
        `You can't add more than ${size} items to this bundle`
      )
    }

    const newBundleChoice = [...bundleChoices, product]
    // console.log(newBundleChoice, "Arr containing fulll shopify product items")
    add()
    setBundleChoices(newBundleChoice)
    updateProgressBar(newBundleChoice)
    //  }
  }
  function removeFromBundler({ index, remove, counter }) {
    if (counter <= 0) {
      return
    }
    const arr = [...bundleChoices]

    const newArrIndex = arr.findIndex((el) => {
      return el.index === index
    })

    arr.splice(newArrIndex, 1)

    remove()
    setBundleChoices(arr)
    updateProgressBar(arr)
  }

  return {
    bundleChoices,
    progressBar,
    addToBundler,
    removeFromBundler,
    addToCart,
    bundleChoices,
  }
}


