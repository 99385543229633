import React from "react"

export function BundleChoice({ changeBundle, chooseBundle }) {
  return (
    <div className="step">
      <h3 className="laptop">1. Select Bundle</h3>
      <h3 className="phone">2. Select Bundle</h3>
      <div className="btn-select-container">
        <button
          onClick={() => changeBundle(5)}
          className={`select ${chooseBundle == 5 ? "selected" : ""}`}
        >
          Pick any 5{" "}
          <div>
            <span>$69.90</span>
            <span> $48.95</span>
          </div>
        </button>
        <button
          onClick={() => changeBundle(8)}
          className={`select ${chooseBundle == 8 ? "selected" : ""}`}
        >
          Pick any 8
          <div>
            <span>$99.92</span>
            <span> $69.92</span>
          </div>
        </button>
        <button disabled={true} className="select out-of-stock"
          //onClick={() => changeBundle(6)}
          //className={`select ${chooseBundle == 6 ? "selected" : ""}`}
        >
          Welcome bundle
          <div>
          <span className="warning">Out of stock</span>
            {/* <span>$79.99</span>
            <span> $55.99</span> */}
          </div>
        </button>
      </div>
    </div>
  )
}
