import React from "react"
import { useCarousel } from "../hooks/useCarousel"

const CarouselContext = React.createContext({})

function makeIndices(start, delta, num) {
  const indices = []

  while (indices.length < num) {
    indices.push(start)
    start += delta
  }

  return indices
}

export const CarouselWrapper = ({ children, product }) => {
  const slides = product.images
  const length = slides.length
  const numActive = Math.min(length, 1)
  const [active, setActive, handlers, style, next, prev] = useCarousel(
    length,
    5000,
    { slidesPresented: numActive }
  )

  const beforeIndices = makeIndices(slides.length - 1, -1, numActive)
  const afterIndices = makeIndices(0, +1, numActive)
  return (
    <CarouselContext.Provider
      value={{
        slides,
        length,
        numActive,
        active,
        setActive,
        handlers,
        style,
        next,
        prev,
        product,
        beforeIndices,
        afterIndices,
      }}
    >
      {children}
    </CarouselContext.Provider>
  )
}

export const useCarouselContext = () => React.useContext(CarouselContext)
