import styled from "styled-components"

export const ProgressBar = styled.div`
  margin-left: 40px;
  display: grid;
  grid-template-rows: repeat(${(props) => props.size}, 1fr);
  height: 100%;
  width: 60px;
  align-items: center;

  @media (max-width: 768px) {
    margin: 20px 0 0;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(${(props) => props.size}, 1fr);
    width: auto;
  }
`

export const Progress = styled.div`
  opacity: ${(props) => (props.state ? "1" : ".5")};
`

export const PopUpSection = styled.section`
  --borderR: 10px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 50;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .inner {
    height: 500px;
    z-index: 100;
    width: 600px;
    background: var(--beige);
    border-radius: var(--borderR);
    @media (max-width: 769px) {
      height: 400px;
      width: 540px;
    }

    @media (max-width: 600px) {
      width: 500px;
      height: 400px;
    }

    @media (max-width: 520px) {
      max-width: 98vw;
      height: 0;
      padding-bottom: 90%;
      position: relative;
    }
  }
  /*  */
  /* .inner {
    z-index: 101;
    /* @media (max-width: 769px) {
      left: 30%;
      right: 30%;
      top: 30%;
      bottom: 30%;
    }

    @media (max-width: 425px) {
      left: 25%;
      right: 25%;
      top: 30%;
      bottom: 30%;
    }

    @media (max-width: 320px) {
      left: 20%;
      right: 20%;
      top: 30%;
      bottom: 30%;
    }

    h2 {
      text-align: center;
    } 
    z-index: 100;
    border-radius: 10px;
    position: absolute;
    left: 35%;
    right: 35%;
    top: 35%;
    bottom: 35%;
    margin: auto;
    background-color: var(--beige);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: var(--darkBlue);
  } */

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    z-index: 49;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const PopUpContainer = styled.aside`
  width: 100%;
  height: 100%;
  border-radius: var(--borderR);
  overflow: hidden;
  position: relative;
  padding: 50px 70px 20px;
  .arrows {
    /* z-index: -1; */
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50px;
    transform: translateY(-50%);
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
      display: inline-block;
      font-size: 2rem;
      color: var(--darkBlue);
    }

    @media (max-width: 760px) {
      padding: 15px;
    }
  }
  .popUpProductArea {
    height: 100%;
    width: 100%;
    /* background: #000; */
    border-radius: var(--borderR);
    z-index: 1000;
  }

  @media (max-width: 769px) {
    padding: 30px 42px 12px;
  }

  @media (max-width: 520px) {
    position: absolute;
  }
`
// export const PopUpContainer = styled.article`
//   width: 100%;
//   height: 100%;
//   border-radius: var(--borderR);
//   overflow: hidden;
//   position: relative;
//   padding: 50px 70px;
//   /*
//   .popUpProductArea {
//     height: 100%;
//     width: 100%;
//     /* background: #000; */
//   /* border-radius: var(--borderR); */
//   /* } */

//   /* .closePopUp {
//     width: 1.5rem;
//     height: 1.5rem;
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     cursor: pointer; */
//   /* &::after {
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       content: "\274c"; /* use the hex value here... */
//   /* font-size: 50px;
//       color: #fff;
//       line-height: 100px;
//       text-align: center;
//     } */
//   /* } */
// `

export const ClosePopUp = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  @media (max-width: 760px) {
    top: 15px;
    right: 15px;
  }
  .one {
    height: 1.5rem;
    width: 2px;
    margin-left: 0.5rem;
    background: var(--darkBlue);
    transform: rotate(45deg);
    z-index: 1;
  }
  .two {
    height: 1.5rem;
    width: 2px;
    background: var(--darkBlue);
    z-index: 2;
    transform: rotate(90deg);
  }
`
