import React from "react"
import styled from "styled-components"
import BodyWrapper from "../../templates/BodyWrapper"

import {
  Fire04,
  OpenOven03,
  Oven01,
  Pastry05,
  Tray02,
  BakingBanner01,
  BakingBanner02,
  BakingBanner03,
  BakingBanner04,
  BakingBanner05,
} from "../../utils/imageUpload"

const CookingBanner = () => {
  return (
    <Banner>
      <BodyWrapper>
        <h1>Are your taste buds ready to party?</h1>
        <div className="grid">
          <div className="laptop">
            <div className="item">
              <img src={Oven01} alt="" />
              <img src={BakingBanner01} alt="" />
            </div>
            <div className="item">
              <img src={Tray02} alt="" />
              <img src={BakingBanner02} alt="" />
            </div>
          </div>
          <div className="laptop">
            <div className="item">
              <img src={OpenOven03} alt="" />
              <img src={BakingBanner03} alt="" />
            </div>
            <div className="item">
              <img src={Fire04} alt="" />
              <img src={BakingBanner04} alt="" />
            </div>
          </div>
          <div className="laptop">
            <div className="item">
              <img src={Pastry05} alt="" />
              <img src={BakingBanner05} alt="" />
            </div>
          </div>
        </div>
      </BodyWrapper>
    </Banner>
  )
}

export default CookingBanner

const Banner = styled.div`
  margin-top: 100px;
  background: var(--blue);
  text-align: center;
  padding: 50px 0;

  h1 {
    margin-bottom: 50px;
    text-transform: uppercase;
    color: var(--darkBlue);
    font-weight: 500;
  }

  .grid {
    display: flex;
    justify-content: center;
  }

  .laptop {
    justify-content: center;
    display: flex;
  }
  @media (max-width: 768px) {
    .grid {
      flex-direction: column;
    }
    .laptop {
    }
  }

  .item {
    width: 150px;
    margin: 0 10px;
  }
`
